<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 realtime-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="realtime-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_realtime-help-online'
        }"
      >
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("menu", "applications", "5dmaps-realtime") }}
        </div>
        <template v-slot:right>
          <button @click="toggleSearch">
            <icon icon="#cx-hea1-search" />
          </button>
        </template>
      </top-header-menu-wrapper>
      <section class="search-wrapper applications-search" v-if="search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="displayLabelName('realtime', 'menu', 'search')"
          />
          <div class="icon search"><svg-icon icon="lense"></svg-icon></div>
        </div>
      </section>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreen }} - {{ bottomScreen }} /
          {{ paginationData.total }}</span
        >
      </div>
    </template>
    <div class="scroll"></div>
    <ul class="clebex-item-section pill" v-if="maps && maps.length">
      <li
        class="clebex-item-section-item"
        v-for="map in listOfMaps"
        :key="map.id"
      >
        <router-link
          :to="{ name: 'r_realtime-map', params: { mapId: map.id } }"
          class="clebex-item-content-wrapper"
        >
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                icon="#cx-men1-location"
                class="realtime-map-icon"
                :class="{ active: map.realtime }"
              ></icon>
            </span>
          </span>
          <span class="label">
            <span class="highlight">{{ map.name }}</span>
          </span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon icon="#cx-app1-information"></icon>
            </span>
          </span>
        </router-link>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray"></nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";
import paginationMixin from "@/services/mixins/pagination/pagination-mixin";

export default {
  name: "RealtimeMaps",
  mixins: [helpOnlineMixin, paginationMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  data() {
    return {
      searchQuery: "",
      search: false,
      helpSlug: "real-time"
    };
  },
  created() {
    this.getMaps();
  },
  computed: {
    ...mapState("realtime", ["maps"]),
    listOfMaps() {
      const query = this.searchQuery;
      if (this.maps && this.maps.length) {
        if (query && query.length > 1) {
          return this.maps.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.maps;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("realtime", ["getMaps"]),
    toggleSearch() {
      this.query === "";
      this.search = !this.search;
    }
  }
};
</script>
